import React, { useCallback } from 'react'
import _ from 'lodash'
import tw from 'twin.macro'
import { Container, Header } from 'semantic-ui-react'
import { useLocation } from '@gatsbyjs/reach-router'

import dayjs from '../../utils/dayjsExtend'
import { currencyFormatter } from '../../utils/utils'
import TailwindLayout from '../../components/Layouts/TailwindLayout'
import Layout from '../../components/Layouts/cakeDefault'
import { Heading1, Heading2 } from '../../components/BanhSinhNhatPage/Base'
import { GreenBorder } from '../../components/BanhSinhNhatPage/Base'

export default function OrderTrackingPage() {
  const [fetching, setFetching] = React.useState(false)
  const [onlineOrder, setOnlineOrder] = React.useState({})
  const [error, setError] = React.useState('')

  // get from location: order param for searching
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const onlineOrderId = searchParams.get('onlineOrderId')
  const createdTs = searchParams.get('createdTs') // timestamp
  const cfReceive = searchParams.get('cfReceive')

  React.useEffect(() => {
    // fetch online order
    async function fetchOrder() {
      let url = `${process.env.GATSBY_API_URL}/online-orders/search?createdTs=${createdTs}&onlineOrderId=${onlineOrderId}`
      if (cfReceive) {
        url += `&cfReceive=${cfReceive}`
      }
      const data = await fetch(url)
      const onlineOrder = await data.json()

      if (onlineOrder && onlineOrder.message) {
        setError(onlineOrder.message)
        return
      }
      if (!onlineOrder || !onlineOrder._id) {
        setError('Không nhận được dữ liệu về online order')
        return
      }
      setOnlineOrder(onlineOrder)
    }
    setFetching(true)
    fetchOrder()
      .catch((error) => {
        console.log(error)
        setError(error.message)
      })
      .finally(() => {
        setFetching(false)
      })
  }, [createdTs, onlineOrderId, cfReceive])

  const shipping =
    _.get(onlineOrder, 'shipping.collect') ||
    onlineOrder?.serverCalculatedShipCollect

  const checkShowTime = useCallback(
    (stage) => {
      if (stage.status !== 'ready') {
        return !!stage.time
      }
      const stages = _.get(onlineOrder, 'arrayStages')
      const cakeDone = _.find(stages, { status: 'ready' }) || {}
      const shipTime = _.find(stages, { status: 'ship' }) || {}
      if (cakeDone.time && shipTime.time) {
        return (
          dayjs(cakeDone.time).format('DD/MM/YYYY') ===
          dayjs(shipTime.time).format('DD/MM/YYYY')
        )
      }
      return !!cakeDone.time
    },
    [onlineOrder]
  )

  return (
    <TailwindLayout>
      <Layout
        endPoint={'orders/tracking'}
        title={'Savor Cake | Order Tracking'}
      >
        <div tw="mt-8 md:mt-10 px-3 lg:px-20 xs:px-4 sm:px-5 md:w-3/4 mx-auto lg:container lg:max-w-screen-lg! text-savor-cake-primary-green-avocado-100 border-savor-cake-primary-green-avocado-100">
          {/* title with onlineOrderId */}
          <Heading1 tw="text-2xl lg:text-3xl">
            Tình trạng đơn hàng {onlineOrderId}
          </Heading1>

          {fetching && (
            <Container style={{ marginTop: 20 }}>
              <Header>Đang tải ...</Header>
            </Container>
          )}
          {error && (
            <Container style={{ marginTop: 20 }}>
              <Header>Lỗi khi tải đơn hàng của bạn</Header>
              <p>{error}</p>
            </Container>
          )}
          {!error && !fetching && onlineOrder ? (
            <>
              {/* status timeline */}
              <div tw="mb-3 md:mb-6 text-center text-base sm:text-lg">
                Cập nhật lần cuối lúc:{' '}
                {dayjs(
                  onlineOrder.updatedAt ||
                    onlineOrder.updated ||
                    onlineOrder.created
                ).format('HH:mm DD/MM/YYYY')}
              </div>
              <ol tw="px-5 mx-auto items-center sm:flex sm:justify-center sm:gap-1 sm:items-start  text-gray-400 w-full">
                {(onlineOrder?.arrayStages || []).map((stage) => {
                  const stageColorCondition =
                    stage.status === 'cancel'
                      ? tw`bg-savor-cake-secondary-blueberry-0`
                      : stage.time
                      ? tw`bg-savor-cake-primary-green-avocado-100`
                      : null

                  const textColorCondition =
                    stage.status === 'cancel'
                      ? tw`text-savor-cake-secondary-blueberry-0`
                      : stage.time
                      ? tw`text-savor-cake-primary-green-avocado-100`
                      : null

                  return (
                    <li
                      tw="relative mb-6 sm:mb-0 sm:w-1/3"
                      css={
                        stage.time &&
                        tw`text-savor-cake-primary-green-avocado-100`
                      }
                    >
                      <div tw="flex items-center">
                        {/* dot */}
                        <div
                          tw="z-10 flex items-center justify-center w-5 h-5 rounded-full flex-shrink-0 bg-gray-400"
                          css={stageColorCondition}
                        ></div>
                        {/* line */}
                        <div
                          tw="w-full h-0.5 bg-gray-400"
                          css={stageColorCondition}
                        ></div>
                      </div>
                      <div tw="mt-3 sm:pb-8">
                        <h3
                          tw="text-lg md:text-xl font-semibold"
                          css={textColorCondition}
                        >
                          {stage.stageName}
                        </h3>
                        <time
                          tw="block mb-2 text-sm font-normal leading-none"
                          css={textColorCondition}
                        >
                          {checkShowTime(stage)
                            ? dayjs(stage.time).format('HH:mm DD/MM/YYYY')
                            : null}
                        </time>
                        {stage.status === 'ship' ? (
                          <p tw="text-base font-medium">
                            Số shipper:{' '}
                            {_.get(onlineOrder, 'shipping.shipperPhoneNumber')}
                          </p>
                        ) : null}
                      </div>
                    </li>
                  )
                })}
              </ol>

              {/* order info */}
              <GreenBorder>
                <AdjustedPara tw="font-savor-cake-secondary-quicksand!">
                  <Heading2 tw="text-base sm:text-lg md:text-xl text-left">
                    Chi tiết đơn
                  </Heading2>
                  <div>
                    Người nhận: {_.get(onlineOrder, 'receiverCustomer.name')} -{' '}
                    {_.get(onlineOrder, 'receiverCustomer.cellphone')}
                  </div>
                  <div>
                    Người đặt: {_.get(onlineOrder, 'orderCustomer.name')} -{' '}
                    {_.get(onlineOrder, 'orderCustomer.cellphone')}
                  </div>
                  <div>
                    Sản phẩm:
                    <div tw="ml-2">
                      {onlineOrder?.items?.map((item) => {
                        const textBase = _.find(item.variants, {
                          type: 'TEXT_IN_BASE',
                        }) || { description: '<KHÔNG VIẾT>' }
                        const textCake = _.find(item.variants, {
                          type: 'TEXT_IN_CAKE',
                        }) || { description: '<KHÔNG VIẾT>' }
                        return (
                          <li tw="marker:mr-0.5!">
                            {item.sku} {item.description}:{' '}
                            {currencyFormatter.format(item.soldPrice)} x{' '}
                            {item.quantity} ={' '}
                            {currencyFormatter.format(item.lineTotal)}
                            {item.textInBase !== 'notCake' ? (
                              <div tw="ml-6">
                                + Chữ trên đế:{' '}
                                {item.textInBase || textBase.description}
                              </div>
                            ) : null}
                            {item.textInBase !== 'notCake' ? (
                              <div tw="ml-6">
                                + Chữ trên mặt bánh:{' '}
                                {item.textInCake || textCake.description}
                              </div>
                            ) : null}
                          </li>
                        )
                      })}
                    </div>
                  </div>

                  <div>
                    Thành tiền:{' '}
                    {currencyFormatter.format(onlineOrder.orderValue)}
                  </div>
                  <div>
                    Tiền ship:{' '}
                    {!_.isNumber(shipping)
                      ? 'Đang cập nhật'
                      : currencyFormatter.format(
                          _.get(onlineOrder, 'shipping.collect') ||
                            onlineOrder.serverCalculatedShipCollect
                        )}
                  </div>
                  <div>
                    Tổng đơn:{' '}
                    {currencyFormatter.format(onlineOrder.totalOrderValue)}
                  </div>
                  <div>
                    Còn phải thanh toán:{' '}
                    {currencyFormatter.format(onlineOrder.outstandingPayment)}
                  </div>
                  <br />
                  <div>
                    Địa chỉ nhận bánh:{' '}
                    {_.get(onlineOrder, 'receiverCustomer.address')}
                  </div>
                  <div>Thời gian giao: {onlineOrder.deliverDatetimeText}</div>
                  <div>
                    Hình thức thanh toán:{' '}
                    {_.get(onlineOrder, 'payment.method') === 'transfer'
                      ? 'Chuyển khoản'
                      : 'COD'}
                  </div>
                </AdjustedPara>
              </GreenBorder>
            </>
          ) : null}
        </div>
      </Layout>
    </TailwindLayout>
  )
}
const AdjustedPara = tw.div`font-medium text-sm sm:text-base md:text-lg px-4 sm:px-10 text-savor-cake-secondary-blueberry-0`
